import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { Details, Blockquote } from "../components/details.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import MoreProjects from "../components/moreProjects.js";
import round from "../images/unity/round-button.png";
import blurred from "../images/unity/blurred-bg.png";
import glare from "../images/unity/glare-animation.gif";
import activate from "../images/unity/activate.gif";
import popbubble from "../images/unity/popbubble.gif";
import sliderblock from "../images/unity/sliderblock.gif";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">{props.pageContext.frontmatter.shortTitle}</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <div className="nda">
  <h3>
    My work during this internship is under an NDA, thus any data has been
    removed and this will be a high level recap of what I did and learned.
  </h3>
    </div>
    <p>{`I joined the Ad Unit Innovation (AUI) team at Unity as a frontend engineering intern; our mission is to optimize the in-game advertisement experience for users and game publishers alike. During my internship, I helped to launch 7+ experiments which received close to a billion views overall and generated insights for my team to further iterate on advertisement designs.`}</p>
    <Details company={props.pageContext.frontmatter.company} role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="Details" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/247b7ea0e236964adad944f616eca3b8/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvElEQVQ4y32TXWyLURjHd0fCjbghPi7ciMSVjAgRghgR83HHIlz4DBcIxphhJsKWrbZss2XKhqF0Xdls81G2DlPNltFkQ0y7dl1ruq7t+6Ht+/6kH5tEGid5cs7N8zv/8z//Jw1XgPFSnWPgFmBYAK8IIzJ4BfBIqO4AjIoQlCAog18CTyjRE+tP7mnjoHHYQE8f3R+seNzDOL4Poi2vpdnQCN0+HGff4njmwPncTvf5ToZ0feATUAfHUJOiJoDKoB+8Mk2PjWhv1XJw335WZm7nbG4TDW1WAo/7qZpWiXHeHW7PraRs6lV6T5kgJKPY/f8AXUngT5n2ljZWr17BpClTmTFzDkvWHcbc4eSboZ9HW/T072ulfms9D1bV8LXQHH+66kgBjMaAIyGaH1pYnF7EgjkLOb58Jxmzl1JVXI63eYCuFTWYM7RolpRQMf8SrXlGCIZTKHSOEXWMgS+AvtZLZrqe4rWH0W7IpWTZEUzaJ/gabNhmXKRoejZZk/ewPm0zNYeqQFCI2kdTeOjww6iEqfodRRvzeHSomMrt+RRuOkNHTQvhbhc/Tjagy9ahOXaL8r0arPVvwCfHe1N/yohMr76dkjVH0GYV0FPeRNvaHCzXW0FRUYHePhtvLV3EVjQcQUnGJTXQI2Ezf6Rox2lKt17AWmukqUBDV6MJVQjHIaUV1ZzIyYuf5YCYiJszBVCNx0bC/sJMx657aBblcXlbNjeqb2J9bwEhghKV8Nr7GLJ/QYlIKKL8H4UxD30y7dqXHJ2Vz7mFx7l97QZ1dXW0GJ4SDUUpaxO50hDgamOQAoOI2yODOykotYcinUYLBzIN5O6uR3f/PqVlZejv6iAUobNf4NUnidefRUw2Ef+IAK7YlCWiNwGckBy7yR0k7A3w2z2K7PQRGQ7E5zqRUz/8+luKyx+HJaAJ4B/WAG6ZhBBkPAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Header image of Unity AUI intership experience",
            "title": "Header image of Unity AUI intership experience",
            "src": "/static/247b7ea0e236964adad944f616eca3b8/1cfc2/header.png",
            "srcSet": ["/static/247b7ea0e236964adad944f616eca3b8/3684f/header.png 225w", "/static/247b7ea0e236964adad944f616eca3b8/fc2a6/header.png 450w", "/static/247b7ea0e236964adad944f616eca3b8/1cfc2/header.png 900w", "/static/247b7ea0e236964adad944f616eca3b8/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`What did I do?`}</h4>
    <br />
    <br />
    <p>{`As a frontend engineering intern, I worked on two main initiatives:`}</p>
    <ol>
      <li parentName="ol">{`Creating new experiments to further optimize ads shown on the Unity Ad Network`}</li>
      <li parentName="ol">{`Migrating legacy code to modern solutions and reusable components to make the code base lighter and a better experience for users`}</li>
    </ol>
    <p>{`I learned a lot from both initiatives and was given the autonomy to work like a full-time employee with the team. I was one of 4 frontend engineers on a team of 12 with 3 backend and machine learning engineers, 2 data scientists, 1 full stack developer, 1 product designer and 1 product manager.`}</p>
    <p>{`I also had the opportunity to participate in the internal Hackweek at Unity with a group of XR developers. Our project aimed to prototype two-handed interaction scripts that feel natural and can easily be implemented by developers using Unity's XR Interaction Toolkit.`}</p>
    <h2>{`Experiments`}</h2>
    <p>{`Unity has an advertising network called Unity Ads. Developers can use the SDK that Unity provides to monetize their mobile games by showing ads at certain intervals. Ads typically are shown in a video and static format where a short demonstration of the advertised game is shown with a download button followed by a static image of the game and another download button that prompts users to download it.`}</p>
    <p>{`My team works on optimizing the conversion of these ads by changing the layout and styling of elements controlled by Unity Ads such as the styling and animations on the download button, placement of images and UI, as well as the timing of when certain elements are shown. We also control what variation is shown to each user.`}</p>
    <p>{`Experiments are variations done to the ad and shown on a small subset of traffic and increased to more audiences over time to determine if it improves overall conversion. To do this, my team uses a variety of machine learning and data analysis techniques to help optimize the process. One technique that we often referred to was the multi-armed bandit problem and its solutions. There's a great article by Liliam Weng about the concept `}<a href="https://lilianweng.github.io/lil-log/2018/01/23/the-multi-armed-bandit-problem-and-its-solutions.html" className="link" target="_blank" rel="noreferrer">{`here`}</a>{`.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6fb66054808f1bf683af2973f0990d29/cd7c1/experiment-process.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.11111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABa0lEQVQoz3VSi3KCMBDk/z+tKg9FEXxiW1GRKpBACCTbyYnWjm1mbpK5595uLPxzFIBO6V+mdP++30q95FjRYoFgHmKxXGGzjfHxuUcYRXTfz6NA/zTWwMPMcKVvedbhdEIQhtgnB1RC4JrnyIsSl2uONMuQF8WjGSHUGm3XgfEKBWOoagHRSMi2pZhVC4H1ZovkcCQ0ukd1PKWIFkvYjgte1YSgaVtCUzKOeLeD7Th4GwzhuC6+Lheqs0rOMbJtHNOUHGb6bS1Q0sT3EcznWK5W2CcJ+Q1Sg9AMM01NjpCSYlZ6zuCNx5gFAZbrNdpOUUB2HaEejmxMZwHi3TsGwxE+9wltYehxPQ++P8U23uGcZTeE2zjGeOJT0HE94kP3QoimoXWNNVKCcQ5DkRlofK43JvQTf4pG3uiwzKSiZCgZI24Msvv3UM9K9uSSmlpTA17XYFVFwpjNSGX9JAQh08//7G9T+vXr3Gu+Ad9dsq2hfxRWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Process in creating an experiment",
            "title": "Process in creating an experiment",
            "src": "/static/6fb66054808f1bf683af2973f0990d29/1cfc2/experiment-process.png",
            "srcSet": ["/static/6fb66054808f1bf683af2973f0990d29/3684f/experiment-process.png 225w", "/static/6fb66054808f1bf683af2973f0990d29/fc2a6/experiment-process.png 450w", "/static/6fb66054808f1bf683af2973f0990d29/1cfc2/experiment-process.png 900w", "/static/6fb66054808f1bf683af2973f0990d29/21482/experiment-process.png 1350w", "/static/6fb66054808f1bf683af2973f0990d29/cd7c1/experiment-process.png 1547w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The overall process of creating an experiment on the Unity Ads network
    </p>
    <p>{`Although I was technically a frontend engineer, I had the opportunity to work on all aspects of the experiment process. I helped to conceptualize ideas into mockups, implemented them in the frontend, created experiment templates, and analyzed the outcomes of the experiments. I learned a lot about the psychology of users and how they interact with advertisements, as well as data analysis and basic machine learning concepts.`}</p>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        Examples of experiments I worked on
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <div className="row">
  <div className="column">
    <img src={round} alt="Round button variant on endcard" />
    <p className="caption">
      Round CTA button variant on endcard
    </p>
  </div>
  <div className="column">
    <img src={blurred} alt="Blurred background variant on endcard" />
    <p className="caption">
      Blurred background variant on endcard
    </p>
  </div>
          </div>
          <img style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto"
          }} src={glare} alt="Glare animation on CTA on video screen" />
          <p className="caption" style={{
            textAlign: "center"
          }}>
  Glare animation on CTA on video screen
          </p>
          <div className="row">
  <div className="column-3">
    <img src={popbubble} alt="Bubble popping animation on the CTA on the video screen" />
    <p className="caption">
      Bubble popping animation on the CTA on the video screen
    </p>
  </div>
  <div className="column-3">
   <img src={sliderblock} alt="Scanner animation on the CTA on the video screen" />
    <p className="caption">
      Scanner animation on the CTA on the video screen
    </p>
  </div>
  <div className="column-3">
   <img src={activate} alt="Activating animation on the CTA on the video screen" />
    <p className="caption">
      Activating animation on the CTA on the video screen
    </p>
  </div>
          </div>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <h2>{`Refactoring`}</h2>
    <p>{`The other initiative that I got to work on was refactoring old code into new, modern solutions with reusable components. Previously, we had multiple versions and files with the same button used in different locations, however, with this modernization effort, we were able to condense it down to a single component that is super customizable and reusable for all our use cases. I worked on migrating and refactoring many similar components throughout my internship.`}</p>
    <p>{`To accomplish the feat, we used a CSS-in-JS solution called `}<a href="https://linaria.dev" className="link" target="_blank" rel="noreferrer">{`Linaria`}</a>{` that lets us style components easily and worked with our framework which was Preact. This allowed us to remove the SCSS files we were using previously. Also, because of this modernization, we were able to start using `}<a href="https://storybook.js.org" className="link" target="_blank" rel="noreferrer">{`Storybook`}</a>{`, a UI component explorer, which helps us build components in isolation and overall streamline our testing and documentation processes.`}</p>
    <p>{`While this certainly was not as fun as creating new experiments, I learned a lot about building a reusable component library from the software side. It's super interesting to see how something like Material UI can be taken for granted now that I know how difficult it is to build something like it.`}</p>
    <h4>{`What did I learn?`}</h4>
    <h2>{`Don't get too attached to an idea`}</h2>
    <p>{`When working on experiments in the AUI team, it's surprising to see what sticks and what doesn't with users. While the team may think it will be a good change to make, the data may soon tell us otherwise. So even after putting in the effort to design and implement the idea, users may not like it and we end up scrapping the design, making our work go to "waste". However, without experimenting, we will never have known the true outcome and thus, it's important to keep iterating and trying new things!`}</p>
    <h2>{`Ask for help and you can never be stuck`}</h2>
    <p>{`Coming into the internship, I thought I would be well prepared because I have previously used TypeScript, React, and CSS. However, I soon learned that there was a lot of things I didn't know about including the team's staging and deployment process, the mix between legacy code and new code, or even how the experiment process worked. I was initially scared of asking questions, but over time as my team encouraged me, I would ask questions all the time after trying to figure out the question on my own for around half an hour. Because of this policy I set for myself, I never truly ended up stuck and learned a lot along the way.`}</p>
    <h2>{`Something simple can end up really complicated`}</h2>
    <p>{`Before working on the refactoring initiative, I originally thought it would be pretty simple. I mean making a button could be as simple as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<button>`}</code>{`Download`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`</button>`}</code>{` right? But I soon learned that it could take weeks just to make a simple component like that reusable and customizable for all circumstances. For another component I worked on that only had about 100 lines of code, I ended up getting around 70 comments on my pull request... 70! Thus, I learned the intricacies of creating common component libraries and to keep an open mind when things may seem simple at first.`}</p>
    <h4>{`Wrapping it up`}</h4>
    <br />
    <br />
    <p>{`Working at Unity was an absolute blast! While I never thought I would end up working on advertising, I learned a lot from the process including prototyping, data analysis, and best practices in software development. I'm proud of what I was able to achieve and I'm excited to continue my journey in software development and learn more about the gaming and graphics industry.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c47b1c3b80b40689e4de06d43a8cf57d/06868/team.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "96.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFKElEQVQ4y2WMe1DTBQDHf3WW9sCN8RJIS03tIY5O8gAllYABQ5AxUQiFTQcDBrjxkMEAB5MBYwM2kAk+eUQoCRmMhQVpaXl1Pa+rfOVdZV1pd/ZPd93lp7POu+763n3u+/3j+/0KZpORPpeNjpb9GPdV02Ay0VxXTZmuiNNnpjgzPUP/iWHePXeeKe9Z6hqbcff1MzLczyGXlQF3B8YyHe22dk6fmUTo6OrF65nm3NwctmYL3eZqBlwN6Ap24j42yInRCQ67nHx46SMGR1+noKyCs+/MMnZqCFVWOq93tqBVq8hV7aGktAxBrSlg4MhRjh3qpb6sELN6B/sNueiL8giPiiQ5XUlnnQl9ZQ3PrQ3HLzSI4ddexdpmQxAELAUqggND/sn/cMBygIMdDuJjZcRvepky1S4SNsWh3pnD6rCVSMNfYPPGGOQpqWyIWcuSp3zpctoZGR0lNDSUqBcjkIZJCQ9/gXDpGoRJzwyff3GViIidONwerv/2F99c+Z3qfT1YK3bgbNzN+JCZC14HH0x3cM7r4i3PCFMeD17vNHfu3OHX27f48eYP/PnnXwjHB0/x/c+/oUpUMT40x/U/4JNrMDlxltqiNN4ctuIZ7+NgWzk3rn3MpYteZjynuXr1CpevfMvxw25mPJOMTAwyOH4YQVNk4VDf+zj3HqDJ4GTi/Zs09n5Cpek4uYooHBYdA/0u1ImRvDpygtm332Z26jS3b/3C3NxZCrMVHHPayNIokGdvRlCk5lBbbmd/VSOKLbtQ766jzOgmOa0YfXEePZ1NDA246Wquw2G3MXDyDdps7bTYW1FmpJCWKiNJFotak0Ni8kYEh62FqckJWkwmagr2kJubR5FWS0mRhp9ufsd9Xf7+B678fIevbvyEzdZKXr6a8qpi8lTbUWYkUaJTYSwvQWi2tjB77j36D1jIS4hDb6hi7NQ4be12utpb2avXs99Ui7lYR//Ro3z25ad0d7VgNBRi0O8hLS6OnHQ5O7an02StRWhqbGKvoQJ57GZWhAaTJE9lYHCE4ddOUWOsIXjxclYuf5aa7J3IZMlMz0zTc9CN62Anqj0qnlm2ioT1UWgL8+nuMyNUlhWQpZSTlZGEQadCq8rkSK8dR2sTa1asZLnoMZaK5/OS9Gl8FszjycUhvJK9nU6nHUNJAdsSYynV5dPQUEN9fTVCeamWa19/RI+tHs0rKRzvbeb8zAgOSwUl0f6c1EdTpYxGER9FfIqM4KXL2JGVydj4STxjJ7BqttHT142r24HZbETQ5Gvxet+kzdKALjWO9sYGOh1WavUFHKlIoSczDFephvxMJaulq9AaSrG2W+nptPBukxaXcgPmnC1UVe+lzqRHyMlSUqnNZuvGNeyWrcNeVcy+QhXypBQil4ZQlbCOtM2xrI9Yy5InlxLwqEBSTDgdlelcNSYypoigPnI5W2Qx5O5SIoQGLyI4KJAAiRh/iZjgQAkhQf5IpVJamxsYGR2it9fJmuefISEhnha7jagNMTwR6EP4isWsDA0iNMCPwAB/Fi0KQli4UIRIJEYs9kUk9kXsK2HBgkdYHRbG+fdmcdqtqHO2s2LZEjIzUrhw6SJbMxQ88OA8Hhf58thCEQvFYkRi8V2RSHRX8PX15b9IJBJ8fHx47tlVHHE7sJrq0KQreCIkiG0ZW/j0y6/IzMrmoYfmEeDvj+Tf3d37+/8d3kMsFhMYKCE1fgMaVT7y+GQio9ez6aVohkfHkCXJmT//Yfz8/O5175/d87t/A4kEWvvWCoiSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The AUI Team",
            "title": "The AUI Team",
            "src": "/static/c47b1c3b80b40689e4de06d43a8cf57d/1cfc2/team.png",
            "srcSet": ["/static/c47b1c3b80b40689e4de06d43a8cf57d/3684f/team.png 225w", "/static/c47b1c3b80b40689e4de06d43a8cf57d/fc2a6/team.png 450w", "/static/c47b1c3b80b40689e4de06d43a8cf57d/1cfc2/team.png 900w", "/static/c47b1c3b80b40689e4de06d43a8cf57d/21482/team.png 1350w", "/static/c47b1c3b80b40689e4de06d43a8cf57d/d61c2/team.png 1800w", "/static/c47b1c3b80b40689e4de06d43a8cf57d/06868/team.png 1974w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">The entire AUI team!</p>
    <p>{`Shoutout to my team AUI MAB for an awesome summer full of memes and learning! Everyone was super supportive and fun to work with. My mentor, Mikel, commented on my performance and said:`}</p>
    <Blockquote text={'"Emily has been an incredible help throughout her internship. She was able to release her first experiment faster than anyone has ever done before - interns and regular employees alike! She has released many front-end experiments over the course of her internship, some of which have been seen nearly a billion times by users all over the world! Over the last few months, Emily has grown and become a crucial member of our team. We could not have hoped for a better performance from a student."'} mdxType="Blockquote" />
– Mikel Gascon, Mentor and Frontend Developer
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      